import React from 'react';

const FormattedInfo = ({
    id,
    title,
    children
}) => (
    <div id={id} style={{textAlign: 'left', margin: '1.45rem 0'}}>
        <div style={{fontSize: '2rem', fontFamily: 'GreatVibes'}}>{title}</div>
        <div style={{fontSize: '1rem', paddingLeft: '2rem', fontFamily: 'Gravity'}}>{children}</div>
    </div>
);

export default FormattedInfo;
