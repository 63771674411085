import React, {Component} from 'react';
import {Parallax} from "react-scroll-parallax";

import cardboard from '../images/box2.jpg';
import paper from '../images/paper.png';
import {byWidthType, WidthContext} from "../App";

const myStyle = {
    width: 'calc(100% - 10rem)',
    textAlign: 'center',
    padding: '5rem',
    backgroundImage: `url(${cardboard})`,
    backgroundSize: 'contain',
    position: 'relative',
    color: 'rgba(0, 0, 0, 0.85)'
};

const myInnerStyle = {
    backgroundColor: '#ddd', //'rgb(181,178,159)'
    padding: '2rem',
    boxShadow: '1px 1px 4px 0px rgba(77,77,77,0.65)',
    display: 'inline-block',
    backgroundImage: `url(${paper})`,
    backgroundSize: 'contain',
};

class Container extends Component {
    render() {
        const {
            style,
            innerStyle,
            children,
            parallax,
            outerChildren,
        } = this.props;

        // TODO y dynamicky k vysce
        const WithParallax = ({children}) => (
            parallax ?
                <Parallax y={[-parallax, parallax]}>
                    {children}
                </Parallax> :
                children
        );

        return (
            <WidthContext.Consumer>
                {(widthType) => {
                    return (
                        <div style={Object.assign(
                            {},
                            myStyle,
                            {
                                padding: byWidthType(widthType, '5rem', '5rem', '2rem'),
                                width: byWidthType(widthType, 'calc(100% - 10rem)', 'calc(100% - 10rem)', 'calc(100% - 4rem)')
                            },
                            style)
                        }>
                            <WithParallax>
                                <div style={Object.assign({}, myInnerStyle, innerStyle)}>
                                    {children}
                                </div>
                            </WithParallax>
                            {outerChildren}
                        </div>
                    );
                }}
            </WidthContext.Consumer>
        );
    }
}

export default Container;
