import Butterfly, {Types} from "./Butterfly";
import mainImg from "../images/main.png";
import Container from "./Container";
import React, {Component} from "react";
import {byWidthType, WidthContext} from "../App";


class Home extends Component {

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }


    render() {
        const windowGlobal = typeof window !== 'undefined' && window;

        return (
            <WidthContext.Consumer>
                {(widthType) => {
                    let butterflies = [];

                    const size = byWidthType(widthType, 300, 150, 120);

                    // butterflies.push(
                    //     <Butterfly
                    //         key="b1"
                    //         type={Types.PURPLE}
                    //         size={size}
                    //         style={{
                    //             right: byWidthType(widthType, -50, -30, -30),
                    //             top: byWidthType(widthType, 30)
                    //         }}
                    //         rotation={220}
                    //     />
                    // );

                    butterflies.push(
                        <Butterfly
                            key="b2"
                            type={Types.BLUE}
                            size={size}
                            style={{
                                left: byWidthType(widthType, -30),
                                bottom: byWidthType(widthType, -50, -20)
                            }}
                            rotation={320}
                        />
                    );


                    return (
                        <Container
                            style={{
                                fontFamily: 'GreatVibes',
                                fontSize: 30,
                                padding: 0,
                                width: '100%',
                                // padding: byWidthType(widthType, '5rem', '1rem', 10),
                                // width: byWidthType(widthType, 'calc(100% - 10rem)', 'calc(100% - 2rem)', 'calc(100% - 20px)'),
                                height: windowGlobal.innerHeight,
                            }}
                            innerStyle={{
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%) translateX(-50%)',
                                left: '50%',
                                width: `calc(${windowGlobal.innerWidth}px - 25%)`,
                                maxWidth: 900,
                            }}
                            outerChildren={butterflies}
                            ref={this.containerRef}
                        >
                            <img
                                src={mainImg}
                                style={{opacity: 0.85, margin: 0, maxWidth: '100%'}}
                            />
                        </Container>
                    );
                }}
            </WidthContext.Consumer>
        );
    }
}

export default Home;
