import Container from "./Container";
import React, {Component} from "react";
import Butterfly, {Types} from "./Butterfly";
import {byWidthType, WidthContext} from "../App";

const ScheduleItem = ({time, action}) => {
    const pre = time.substr(0, time.indexOf(':'));
    const sup = time.substr(time.indexOf(':') + 1);
    return (
        <tr>
            <td style={{textAlign: 'right', paddingRight: '2rem'}}>
                {pre}
                <sup style={{fontSize: '1.2rem'}}>
                    {sup}
                </sup>
            </td>
            <td style={{textAlign: 'left'}}>{action}</td>
        </tr>
    );
};

class Schedule extends Component {

    render() {
        return (
            <WidthContext.Consumer>
                {(widthType) => {
                    const size = byWidthType(widthType, 150, 120, 0);

                    return (
                        <Container
                            parallax={5}
                            innerStyle={{padding: byWidthType(widthType, '2rem 6rem', '2rem 3rem', '2rem')}}
                            outerChildren={[
                                <Butterfly
                                    key="b1"
                                    type={Types.ORANGE}
                                    size={size}
                                    style={{
                                        right: byWidthType(widthType, 200, 105),
                                        top: byWidthType(widthType, 250, 180, 220)
                                    }}
                                    rotation={300}
                                />
                            ]}
                        >
                            <div style={{fontSize: '3rem', fontFamily: 'GreatVibes', marginBottom: '2rem'}}>Harmonogram</div>
                            <div style={{fontSize: byWidthType(widthType, '2rem', '1.7rem'), fontFamily: 'GreatVibes', lineHeight: '2.5rem'}}>
                                <table>
                                    <tbody>
                                        <ScheduleItem time="10:00" action="Příjezd hostů" />
                                        <ScheduleItem time="11:00" action="Obřad" />
                                        <ScheduleItem time="11:30" action="Skupinové focení" />
                                        <ScheduleItem time="12:30" action="Hostina" />
                                        <ScheduleItem time="14:00" action="Krájení dortu" />
                                        <ScheduleItem time="17:00" action="Svatební focení" />
                                        <ScheduleItem time="18:00" action="Raut (gril)" />
                                        <ScheduleItem time="19:00" action="První tanec" />
                                        <ScheduleItem time="22:00" action="Večerní gulášek" />
                                    </tbody>
                                </table>
                            </div>
                        </Container>
                    );
                }}
            </WidthContext.Consumer>
        );
    }
}

export default Schedule;
