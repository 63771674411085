import Container from "./Container";
import React, {Component} from "react";
import FormattedInfo from "./FormattedInfo";
import {byWidthType, WidthContext} from "../App";

const info = {
    'Příjezd': 'Budeme Vás očekávat kolem 10. hodiny dopolední. My na statku budeme už od brzkého rána, takže není problém přijet případně i dříve.',
    'Doprava':
        <ul>
            <li>Taxi - máme dohodnutého řidiče s autem pro odvoz tam i zpět. Minimálně pro dopravu na místo budeme potřebovat znát zájemce předem. Odvoz z oslavy se bude řešit operativně.</li>
            <li>Autobusem z Českého Brodu - k němu se lze přiblížit např. vlakem z Prahy.</li>
            <li>Autem (vlastním) - parkování bude zajištěno v bezprostřední blízkosti statku, případně na protější louce.</li>
        </ul>,
    'Ubytování': 'Na místě jsou 3 pokoje pro 13 lidí (4 + 4 + 5) a na zahradě spousta místa pro stany. ' +
        'Potřebovali bychom ale znát zájemce předem.',
    'Dress-code': 'Semi-formal (bez kravat a podpatků). Chceme naši svatbu pojmout trochu méně formálně a proto prosíme všechny dámy, ' +
        'aby doma nechaly střevíce a jehly (i z praktického důvodu - jedná se o svatbu na zahradě). ' +
        'Pánové, vy prosím zanechte veškeré doplňky typu kravata, motýlek, šátek atp. doma a zhluboka se s námi nadechněte čerstvého letního vzduchu.',
    'Svatební dary': 'Největším darem pro nás bude Vaše přítomnost. ' +
        'Pokud se ale rozhodnete nás něčím obdarovat, tak největší radost nám jistě udělají nějaké malé osobní dárečky nebo příspěvek na naši svatební cestu, ' +
        'kterou zatím nemáme naplánovanou (klokani? kiwi? kdo ví).',
    'Focení': 'Společné focení bude probíhat po obřadu a je omezený počet skupinek (15), takže se budeme snažit skupinky sestavit ještě s předstihem. ' +
        'Nicméně vždy existuje možnost se nechat vyfotit mimo tento časový rámec.',
    'Občerstvení': 'Všeho bude dost! ;) Vegetariáni a nesvíčkovci se nám nahlašte.',
    'Odjezd': 'Žádný oficiální konec není plánovaný. Odjezd je možný buď již zmiňovaným taxi nebo po vlastní ose. Možností je i ranní autobus do Českého Brodu.',
};


class Info extends Component {

    render() {
        return (
            <WidthContext.Consumer>
                {(widthType) => {
                    return (
                        <Container parallax={byWidthType(widthType, 8, 5, 1)}>
                            {
                                Object.keys(info).map((item, i) => (
                                    <FormattedInfo title={item} key={i}>{info[item]}</FormattedInfo>
                                ))
                            }
                        </Container>
                    );
                }}
            </WidthContext.Consumer>
        );
    }
}

export default Info;
