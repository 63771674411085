import Butterfly, {Types} from "./Butterfly";
import Container from "./Container";
import React, {Component} from "react";
import {byWidthType, WidthContext} from "../App";

const When = () => (
    <div>
        <div style={{fontSize: '3rem', marginBottom: '1.5rem'}}>Kdy?</div>
        <div>17. 7. 2019</div>
        <div>11:00</div>
    </div>
);

const Where = ({height}) => {
    return (
        <div>
            <div style={{fontSize: '3rem', marginBottom: '1.5rem'}}>Kde?</div>
            <div style={{marginBottom: '0.5rem'}}>Statek Vitice</div>
            <div>
                <iframe
                    src="https://api.mapy.cz/frame?params=%7B%22x%22%3A14.909801098760326%2C%22y%22%3A50.02981775466975%2C%22base%22%3A%222%22%2C%22layers%22%3A%5B7%5D%2C%22zoom%22%3A16%2C%22url%22%3A%22https%3A%2F%2Fmapy.cz%2Fs%2F3qVwk%22%2C%22mark%22%3A%7B%22x%22%3A%2214.909801098760326%22%2C%22y%22%3A%2250.02981775466975%22%2C%22title%22%3A%22%C4%8D%C3%A1st%20obce%20Vitice%208%2C%20Vitice%22%7D%2C%22overview%22%3Afalse%7D&amp;width=400&amp;height=280&amp;lang=cs"
                    frameBorder="0"
                    style={{
                        border: 'none',
                        width: '100%',
                        height,
                        boxShadow: '1px 1px 3px 0px rgba(77,77,77,0.65)',
                    }}
                />
            </div>
        </div>
    );
};

class Basic extends Component {

    render() {
        return (
            <WidthContext.Consumer>
                {(widthType) => {
                    let butterflies = [];

                    const size = byWidthType(widthType, 200, 150, 90);

                    butterflies.push(
                        <Butterfly
                            key="b1"
                            type={Types.ORANGE}
                            size={size}
                            style={{
                                left: byWidthType(widthType, 30, -55),
                                top: byWidthType(widthType, 400, 180, 220)
                            }}
                            rotation={byWidthType(widthType, 50, 140)}
                        />
                    );

                    const DesktopLayout = () => (
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{width: '49%', verticalAlign: 'top'}}>
                                        <When />
                                    </td>
                                    <td style={{
                                        width: '2%',
                                        height: '100%',
                                        position: 'relative',
                                    }}>
                                        <div style={{
                                            width: 1,
                                            background: 'black',
                                            position: 'absolute',
                                            top: 20,
                                            bottom: 20,
                                            opacity: 0.85,
                                        }} />
                                    </td>
                                    <td style={{width: '49%', verticalAlign: 'top'}}>
                                        <Where height="15rem" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    );

                    const TabletLayout = () => (
                        <table style={{width: '100%'}}>
                            <tbody>
                                <tr>
                                    <td style={{verticalAlign: 'top'}}>
                                        <When />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{padding: '1rem 0'}}>
                                        <hr
                                            style={{
                                                borderColor: 'black',
                                                opacity: 0.85,
                                                width: 'calc(80%)'
                                            }}
                                            size="1"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{verticalAlign: 'top'}}>
                                        <Where height={byWidthType(widthType, null, '25rem', '18rem')} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    );

                    return (
                        <Container
                            style={{
                                fontFamily: 'GreatVibes',
                                fontSize: '2rem',
                                lineHeight: 'normal',
                                padding: byWidthType(widthType, '5rem', '2rem', 10),
                                width: byWidthType(widthType, 'calc(100% - 10rem)', 'calc(100% - 4rem)', 'calc(100% - 20px)')
                            }}
                            innerStyle={{
                                padding: byWidthType(widthType, '2rem', '2rem', 10),
                                width: byWidthType(widthType, 'auto', 'calc(100% - 4rem)', 'calc(100% - 20px)')
                            }}
                            outerChildren={butterflies}
                            parallax={byWidthType(widthType, 8, 5, 1)}
                        >
                            {byWidthType(widthType, <DesktopLayout/>, <TabletLayout/>)}
                        </Container>
                    );
                }}
            </WidthContext.Consumer>
        );
    }
}

export default Basic;
