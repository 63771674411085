import React from 'react';
import {Parallax, ParallaxBanner} from "react-scroll-parallax";
import heart from '../images/heart_white.png';

const Heart = ({
    width,
    offsetX,
    offsetY,
    opacity
}) => (
    <img
        src={heart}
        style={{
            position: 'absolute',
            left: offsetX,
            top: offsetY,
            margin: 0,
            width,
            opacity,
            filter: 'blur(1px)'
        }}
    />
);

const Image = ({
    height,
    image
}) => (
    <ParallaxBanner
        style={{height}}
        layers={[
            {
                amount: 0.1,
                image,
            }
        ]}
    >
        <Parallax y={[`${height / 2}px`, `-${height / 2}px`]} styleOuter={{height}} styleInner={{height: 2 * height}} >
            {
                Array
                    .apply(null, {length: 15})
                    .map(Function.call, Math.random)
                    .map((offsetX, i) => {
                        const offsetY = Math.random();
                        const size = Math.random();
                        const opacity = Math.random();
                        return (
                            <Heart
                                key={i}
                                opacity={0.5 * opacity}
                                width={`${20 * size}px`}
                                offsetX={`${100 * offsetX}%`}
                                offsetY={`${100 * offsetY}%`}
                            />
                        );
                    })
            }
        </Parallax>
    </ParallaxBanner>
);

export default Image;
