import React, {Component} from 'react';
import "./butterfly.css";
import orangeBody from '../images/butterfly3-body.png';
import orangeLWing from '../images/butterfly3-leftwing.png';
import orangeRWing from '../images/butterfly3-rightwing.png';

export const Types = {
    PURPLE: 'PURPLE', BLUE: 'BLUE', ORANGE: 'ORANGE'
};

class Butterfly extends Component {
    render() {
        const {
            style,
            type,
            size,
            rotation,
        } = this.props;

        let butterfly = null;

        switch (type) {
            case Types.BLUE: {
                const scale = size / 500;
                butterfly = (
                    <div className="futterbly" style={{transform: `rotateZ(${rotation}deg) rotate3d(0, 1, 0, 0deg) scale3d(${scale}, ${scale}, ${scale})`, width: size, height: size}}>
                        <div className="left wing" />
                        <div className="right wing" />
                    </div>
                );
            } break;
            case Types.PURPLE: {
                const scale = size / 400;
                butterfly = (
                    <div className="butterfly" style={{transform: `rotateZ(${rotation}deg) scale(${scale},${scale})`, width: size, height: size}}>
                        <div className="leftwing" />
                        <div className="rightwing" />
                    </div>
                );
            } break;
            case Types.ORANGE: {
                butterfly = (
                    <div className="butterfly3" style={{width: size, transform: `rotateZ(${rotation}deg)`}}>
                        <div className="body">
                            <img src={orangeBody} />
                        </div>
                        <div className="leftwing3">
                            <img src={orangeLWing} />
                        </div>
                        <div className="rightwing3">
                            <img src={orangeRWing} />
                        </div>
                    </div>
                );
            } break;
        }

        if (butterfly) {
            return (
                <div className="butterfly-container" style={style}>
                    {butterfly}
                </div>
            )
        }
        return null;
    }
};

export default Butterfly;
