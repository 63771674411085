import Container from "./Container";
import React from "react";
import mainVideo from '../media/main.mp4';
import danceVideo from '../media/dance.mp4';
import speech1Video from '../media/speech1.mp4';
import speech2Video from '../media/speech2.mp4';
import guestsVideo from '../media/guests.mp4';
import YoutubeVideo from "./YoutubeVideo";
import Photos from "./Photos";
import paper from "../images/paper.png";

const Tab = ({ title, onClick, isSelected }) => (
    <div
        style={{
            display: 'inline-block',
            height: '100%',
            cursor: 'pointer',
            border: '0px solid black',
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
            width: 100,
            margin: '0 0.3rem',
            backgroundImage: `url(${paper})`,
            borderBottom: isSelected ? 'none' : '1px solid #ddd',
            opacity: isSelected ? 1 : 0.7,
            textAlign: 'center',
        }}
        onClick={onClick}
    >
        <span style={{
            fontFamily: 'GreatVibes',
            fontSize: '40px',
            lineHeight: '50px',
        }}>
            {title}
        </span>
    </div>
);

class Media extends React.Component {
    constructor(props) {
        super(props);

        this.state = { selected: 'f' };
    }

    render() {
        const {
            selected,
        } = this.state;

        return (
            <Container style={{minHeight: '100vh'}} innerStyle={{ display: 'block', backgroundSize: 'auto' }}>
                <div style={{
                    height: 50,
                    position: 'relative',
                    top: 'calc(-50px - 2rem)',
                    left: '-1.5rem',
                    textAlign: 'left',
                }}>
                    <Tab title="foto" isSelected={selected === 'f'} onClick={() => this.setState({ selected: 'f' })} />
                    <Tab title="video" isSelected={selected === 'v'} onClick={() => this.setState({ selected: 'v' })} />
                </div>
                {
                    selected === 'f' ?
                        <Photos /> :
                        <React.Fragment>
                            <YoutubeVideo url="https://youtu.be/GFSIcpHF-wU" title="Svatební video" size={134} localUrl={mainVideo} fileName="Svatba Lea a Tom.mp4" />
                            <YoutubeVideo url="https://youtu.be/Hu5osYv8Hc0" title="Proslov svědka" size={54} localUrl={speech1Video} fileName="Svatba Lea a Tom (proslov svědka).mp4" />
                            <YoutubeVideo url="https://youtu.be/sdgqbkLfzq8" title="Proslov otce nevěsty" size={68} localUrl={speech2Video} fileName="Svatba Lea a Tom (proslov otce nevěsty).mp4" />
                            <YoutubeVideo url="https://youtu.be/RiTrW2_1x14" title="První tanec" size={120} localUrl={danceVideo} fileName="Svatba Lea a Tom (první tanec).mp4" />
                            <YoutubeVideo url="https://youtu.be/jQxCYKGTiwU" title="Od hostů" size={437} localUrl={guestsVideo} fileName="Svatba Lea a Tom (video od hostů).mp4" />
                        </React.Fragment>
                }
            </Container>
        );
    }
}

export default Media;
