import {byWidthType, WidthContext} from "../App";
import React from "react";
import ReactPlayer from "react-player";

class YoutubeVideo extends React.Component {
    render() {
        const {
            url,
            title,
            size,
            localUrl,
            fileName,
        } = this.props;

        return (
            <WidthContext.Consumer>
                {(widthType) => {

                    const width = byWidthType(widthType, 640, 400, 320);
                    const height = byWidthType(widthType, 360, 225, 180);

                    return (
                        <div
                            style={{
                                margin: '3rem 0',
                                textAlign: 'center',
                            }}
                        >
                            <div style={{
                                width,
                                display: 'inline-block'
                            }}>
                                <table
                                    style={{
                                        fontFamily: 'GreatVibes',
                                        fontSize: '2rem',
                                        textAlign: 'left',
                                        width: '100%',
                                    }}
                                >
                                    <tr>
                                    <td>{title}</td>
                                    <td
                                        style={{
                                            fontSize: '0.7rem',
                                            fontFamily: 'Gravity',
                                            fontStyle: 'italic',
                                            marginLeft: '0.5rem',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <a href={localUrl} download={fileName} style={{ color: 'black' }}>Stáhnout ({size} MB)</a>
                                    </td>
                                    </tr>
                                </table>
                                <ReactPlayer
                                    url={url}
                                    controls
                                    width={width}
                                    height={height}
                                />
                            </div>
                        </div>
                    )
                }}
            </WidthContext.Consumer>
        );
    }
}

export default YoutubeVideo;
