import React, { Component } from "react"

import "./components/font.css"

import Container from "./components/Container";
import { ParallaxProvider } from 'react-scroll-parallax';
import Image from "./components/Image";
import imgRings from './images/DSC_8750.jpg';
import imgRocks from './images/IMG_20181216_114759.jpg';
import imgForest from './images/DSO_9153.jpg';
import imgForest2 from './images/DSO_9222.jpg';
import imgForest3 from './images/DSO_9169.jpg';
import Home from "./components/Home";
import Basic from "./components/Basic";
import Info from "./components/Info";
import Schedule from "./components/Schedule";
import Countdown from "./components/Countdown";
import {Link} from "react-router-dom";
import paper from "./images/paper.png";
import {Shake} from "reshake";

export const WidthType = {Mobile: 'mobile', Tablet: 'tablet', Desktop: 'desktop'};

export const WidthContext = React.createContext(WidthType.Desktop);

const is = (value) => value !== null && value !== undefined;

export const byWidthType = (widthType, desktop, tablet, mobile) =>
    widthType === WidthType.Desktop ?
        desktop :
        widthType === WidthType.Tablet ?
            (is(tablet) ? tablet : desktop) :
            (is(mobile) ?
                mobile :
                is(tablet) ? tablet : desktop);

const bubbleSize = 150;
const BubbleLink = () => (
        <Link to="/foto">
            <div
                style={{
                    position: 'fixed',
                    top: 20,
                    right: 20,
                    color: 'white',
                    zIndex: 1000,
                }}
            >
                <Shake
                    h={0}
                    v={0}
                    r={20}
                    dur={1000}
                    int={50}
                    max={100}
                    fixed={true}
                    fixedStop={false}
                    freez={false}
                >
                    <div
                        style={{
                            width: bubbleSize,
                            height: bubbleSize,
                            borderRadius: '50%',
                            display: 'inline-block',
                            // lineHeight: `${bubbleSize}px`,
                            textAlign: 'center',
                            fontFamily: 'GreatVibes',
                            margin: '0.3rem',
                            backgroundImage: `url(${paper})`,
                            backgroundSize: 'contain',
                            color: 'black',
                            boxShadow: '1px 1px 4px 0px rgba(77,77,77,0.65)',
                        }}
                    >
                        <div style={{ position: 'absolute', top: '12%', width: bubbleSize, fontSize: '3rem', left: '-1%' }}>
                            <div>Foto</div>
                            <div>Video</div>
                        </div>
                    </div>
                </Shake>
            </div>
        </Link>
);

class App extends Component {

    constructor(props) {
        super(props);
        this.homeRef = React.createRef()
    }

    render() {
        const width = typeof window !== 'undefined' && window.innerWidth;
        const type = width > 840 ? WidthType.Desktop : width > 480 ? WidthType.Tablet : WidthType.Mobile;

        return (
            <ParallaxProvider>
                <WidthContext.Provider value={type}>

                    <BubbleLink />

                    <Home ref={this.homeRef} />

                    <Image height={byWidthType(type, 1200, 500, 300)} image={imgRocks} />

                    <Basic/>

                    <Image height={byWidthType(type, 600, 600, 350)} image={imgForest} />

                    <Info />

                    <Image height={byWidthType(type, 600, 500, 280)} image={imgForest2} />

                    <Schedule />

                    <Image height={byWidthType(type, 700, 350, 200)} image={imgForest3} />


                    <Container parallax={byWidthType(type, 10, 5 , 1)}>
                        <div style={{
                            fontFamily: 'GreatVibes',
                            fontSize: '4rem',
                            margin: '3rem',
                            lineHeight: 'normal',
                        }}>Těšíme se na Vás!</div>
                    </Container>

                    <Image height={byWidthType(type, 600, 350, 200)} image={imgRings} />

                    <Countdown />

                </WidthContext.Provider>
            </ParallaxProvider>
        );
    }

}

export default App
