import React, {Component} from 'react';
import { default as CountdownNow } from 'react-countdown-now';
import paper from "../images/paper.png";

const date = new Date(2019, 6, 17, 11);

const bubbleSize = 60;

const Bubble = ({value, title}) => (
    <div
        style={{
            width: bubbleSize,
            height: bubbleSize,
            borderRadius: '50%',
            display: 'inline-block',
            // lineHeight: `${bubbleSize}px`,
            textAlign: 'center',
            fontFamily: 'GreatVibes',
            margin: '0.3rem',
            backgroundImage: `url(${paper})`,
            backgroundSize: 'contain',
            color: 'black',
            boxShadow: '1px 1px 4px 0px rgba(77,77,77,0.65)',
        }}
    >
        <div style={{ position: 'absolute', top: '12%', width: bubbleSize }}>
            <div style={{ fontSize: '2.2rem' }}>{value}</div>
            <div style={{ fontSize: '1.1rem', marginTop: '-0.8rem' }}>{title}</div>
        </div>
    </div>
);

const titleMap = {
    days: {
        d: 'dní',
        1: 'den',
        2: 'dny',
        3: 'dny',
        4: 'dny',
    },
    hours: {
        d: 'hodin',
        1: 'hodina',
        2: 'hodiny',
        3: 'hodiny',
        4: 'hodiny'
    },
    minutes: {
        d: 'minut',
        1: 'minuta',
        2: 'minuty',
        3: 'minuty',
        4: 'minuty'
    },
    seconds: {
        d: 'sekund',
        1: 'sekunda',
        2: 'sekundy',
        3: 'sekundy',
        4: 'sekundy'
    }
};

const getTitle = (type, value) => {
    const typeMap = titleMap[type];
    return typeMap[value] || typeMap.d;
};

const renderer = ({
    total,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
    completed,
}) => {
    return completed ? null : (
        <React.Fragment>
            <Bubble value={days} title={getTitle('days', days)} />
            <Bubble value={hours} title={getTitle('hours', hours)} />
            <Bubble value={minutes} title={getTitle('minutes', minutes)} />
            <Bubble value={seconds} title={getTitle('seconds', seconds)} />
        </React.Fragment>
    );
};

class Countdown extends Component {
    render() {
        return (
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    color: 'white',
                    zIndex: 100,
                }}
            >
                <CountdownNow
                    date={date}
                    renderer={renderer}
                />
            </div>
        );
    }
}

export default Countdown;
