import React from "react";
import Gallery from "react-photo-gallery";
import photosJson from '../_generated/photos';
import { Lightbox } from "react-modal-image";

class Photos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        Object.keys(photosJson).map((key) => this.state[key] = {
            currentImage: 0,
            viewerIsOpen: false,
        });
    }

    render() {
        return (
            <div>
                <div style={{
                    fontSize: '0.8rem',
                    fontFamily: 'Gravity',
                    fontStyle: 'italic',
                    marginBottom: '-2rem',
                    textAlign: 'right',
                }}>
                    <a href="https://drive.google.com/uc?export=download&id=18Si-h5EnHEzl9vqUSnxSsDHMLVGdygir" download="Svatba Lea a Tom.zip" style={{ color: 'black' }}>Stáhnout vše (2,2 GB)</a>
                </div>
                {
                    Object.keys(photosJson)
                        .sort((a, b) => photosJson[a].order - photosJson[b].order)
                        .map((key) => {
                            const { name, photos } = photosJson[key];

                            const {
                                [key]: {
                                    currentImage,
                                    viewerIsOpen,
                                }
                            } = this.state;

                            return (
                                <div key={key}>
                                    <div
                                        style={{
                                            fontFamily: 'GreatVibes',
                                            fontSize: '3rem',
                                            textAlign: 'left',
                                            width: '100%',
                                            marginBottom: '0.2rem'
                                        }}
                                    >{name}</div>
                                    <div style={{ marginBottom: '10rem' }}>
                                        <Gallery
                                            photos={photos.map(({ thumbSrc, width, height}) => ({ src: thumbSrc, width, height }))}
                                            onClick={(event, { index }) => {
                                                this.setState({
                                                    [key]: {
                                                        currentImage: index,
                                                        viewerIsOpen: true,
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                    {viewerIsOpen ? (
                                        <Lightbox
                                            large={photos[currentImage].src}
                                            hideZoom
                                            onClose={() => {
                                                this.setState({
                                                    [key]: {
                                                        currentImage,
                                                        viewerIsOpen: false,
                                                    }
                                                });
                                            }}
                                            alt=""
                                        />
                                    ) : null}
                                </div>
                            );
                        })
                }
            </div>
        );
    }
}

export default Photos;
